<template>
    <layout>
        <template slot="content">
            <router-view />
        </template>
    </layout>
</template>

<script>
import Layout from '@/views/layouts/Layout';

export default {
    name: 'OnlineEventModule',

    components: {
        Layout
    }
};
</script>

<style lang="scss">
    .room-checkout {
        color: red;
        cursor: pointer;
        font-weight: bolder;
    }
</style>
